import React, { useState } from 'react';
import './Homepage.css';
import cb from '../../images/celebrating.jpg';
import ip from '../../images/ip.jpg';
import NavBar from '../NavBar/NavBar';
import bridge from '../../images/bridge.png';
import community from '../../images/community.png';
import jig from '../../images/jigsaw.png';
import responsive from '../../images/responsive.png';
import pc from '../../images/PatentCommer.png';
import ipFile from '../../images/IPFile.png';
import cpfi from '../../images/CustomPatentForIndus.png';
import coi from '../../images/CommunityOfInnovators.png';
import star from '../../images/stars.png';
import ContactUsCommon from '../ContactUsCommon/ContactUsCommon';
import logo from '../../images/Squirrelip.png';
import ld from '../../images/ld.png';
import fb from '../../images/fb.png';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);  // Toggle between true/false
    };

    const scrollToSection = (task) => {
        const servicesSection = document.getElementById(task);
        if (servicesSection) {
            servicesSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const features = [
        {
            id: 1,
            icon: <img src={jig} className='simIcon' />, // replace with an actual icon or image
            title: 'All in 1',
            description: 'Our platform has all the tools innovators need. They can list their patents to catch the attention of industries, easily sell or license their patents, and work together with industries to create custom solutions.'
        },
        {
            id: 2,
            icon: <img src={bridge} className='simIcon' />, // replace with an actual icon or image
            title: 'Building a Bridge',
            description: 'We create a strong link between innovators and industries. This connection helps turn great ideas into real products by bringing the right people together.'
        },
        {
            id: 3,
            icon: <img src={community} className='simIcon' />, // replace with an actual icon or image
            title: 'A Community',
            description: 'We are creating a welcoming space where innovators can meet, share their research, and work together on exciting new projects. This community encourages collaboration and sparks creativity.'
        },
        {
            id: 4,
            icon: <img src={responsive} className='simIcon' />, // replace with an actual icon or image
            title: 'Accessible to All',
            description: 'Whether you are a startup, an individual innovator, or a research institution, our platform is designed to meet your needs—making patent commercialization easy for everyone.'
        }
    ];

    const navigate = useNavigate();
    const handleAbout = () => {
        navigate('/about');
    };
    const handleContact = () => {
        navigate('/contact');
    };

    // const navigate = useNavigate();

    const navigateToSection = (task) => {
        navigate('/' + task);
    };
    return (
        <div className="homepage">
            {/* Navbar*/}
            <NavBar />

            {/* Main Section: Hero + Countdown + Image */}
            <section className="main-section">
                {/* Hero Section */}
                <div className="hero">
                    <h1>"Protect. Collaborate. Innovate."</h1>
                    {/* <p>Say Goodbye to slow commercialization <br></br>& Hello to the marketplace of your invention</p> */}
                    <br></br>
                    <div className='join-us'>
                        <p>Join us and unlock world of posibilities!!</p>
                        <div className="buttons">
                            {/* <button className="join-btn">Join the Wishlist</button> */}
                            <button className="join-btn" onClick={() => scrollToSection('section2')}>Join the Wishlist</button>
                            <button className="about-btn" onClick={handleAbout}>About us</button>
                        </div>
                    </div>
                </div>

                {/* Countdown and Image Section */}
                {/* <div className="countdown-image-container">

                    <div className='countdown-img'>
                        <img src={cb} alt="Coming Soon" className="coming-soon-img" />
                    </div>
                    <div className="countdown">
                        <h2>COMING SOON !!!</h2>
                        <div className="timer">
                            <div className="time-box">
                                <h3>30</h3>
                                <p>Days</p>
                            </div>
                            <div className="time-box">
                                <h3>2</h3>
                                <p>Hours</p>
                            </div>
                            <div className="time-box">
                                <h3>30</h3>
                                <p>Mins</p>
                            </div>
                            <div className="time-box">
                                <h3>30</h3>
                                <p>Secs</p>
                            </div>
                        </div>
                    </div>



                </div> */}
            </section>
            <section className="offer" id="services">
                <h2>What We Offer</h2>
                <div className="offer-cards">
                    <div className="offer-card">
                        <div className="image-container">
                            <img src={pc} alt="Patent Commercialization" className="card-image" />
                        </div>
                        <div className="card-content">
                            <h3>Patent Customization</h3>
                            <p>
                                We customize patents for industries, catering to the unique needs of each sector
                                to ensure comprehensive protection of your innovations.
                            </p>
                            <button>Know More</button>
                        </div>
                    </div>
                    <div className="offer-card">
                        <div className="image-container">
                            <img src={ipFile} alt="Patent Commercialization" className="card-image" />
                        </div>
                        <div className="card-content">
                            <h3>Patent Commercialization</h3>
                            <p>
                                At Squirrel IP, we streamline patent commercialization by linking innovation with
                                industries and developing a platform to bring new ideas to market.
                            </p>
                            <button>Know More</button>
                        </div>
                    </div>
                    <div className="offer-card">
                        <div className="image-container">
                            <img src={coi} alt="Patent Commercialization" className="card-image" />
                        </div>
                        <div className="card-content">
                            <h3>Community of Innovators</h3>
                            <p>
                                Join our innovator community, where you can connect with like-minded individuals and
                                collaborate on groundbreaking projects.
                            </p>
                            <button>Know More</button>
                        </div>
                    </div>
                    <div className="offer-card">
                        <div className="image-container">
                            <img src={cpfi} alt="Patent Commercialization" className="card-image" />
                        </div>
                        <div className="card-content">
                            <h3>Custom Patents for Industries</h3>
                            <p>
                                We provide tailored patent solutions for various industries, ensuring that your
                                innovations receive the protection they deserve.
                            </p>
                            <button>Know More</button>
                        </div>
                    </div>
                </div>
            </section>

            {/* Why Us Section */}

            <section className="why-us-container">
                <h2 className="why-us-title"><img src={star} className="star" />WHY US</h2>
                <div className='why-us'>
                    <p className="why-us-description">
                        At SQUIRREL IP, we are committed to making patent commercialization simple and accessible. Here's why we stand out:
                    </p>
                    <div className="why-us-features">
                        {features.map((feature) => (
                            <div key={feature.id} className="feature-card">
                                <div className="feature-icon">{feature.icon}</div>
                                <h3 >{feature.title}</h3>
                                <p >{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Supported By Section */}
            {/* <section className="supported-by">
                <h2>Supported By</h2>
                <div className="support-logos">
                    <img src="pedal-start-logo.png" alt="Pedal Start" />
                    <img src="bits-pilani-logo.png" alt="BITS Pilani" />
                </div>
            </section> */}

            {/*  Common Contact Us + Footer */}
            <div className="contact-us-container">
                <h1>Contact Us</h1>
                {/* Main Contact Section */}
                <div className="contact-header">

                    <p>We'll be happy to hear from you</p>
                    <a href="https://forms.gle/qSQUZYnaAMJuFhcZ7" target="_blank" rel="noopener noreferrer">
                        <button className="contact-btn" id='section2'>Fill this form and start your journey with us!</button>
                    </a>
                    {/* <p>Join our wishlist to get premium membership</p>
                    <div className="subscribe-section">
                        <input
                            type="email"
                            placeholder="Enter your email here"
                            className="email-input"
                        />
                        <button className="subscribe-btn">SUBSCRIBE</button>
                    </div> */}
                </div>
            </div>
            {/* Footer Section */}
            <footer className="contact-footer">
                <div className="footer-left">
                    <img src={logo} alt="Squirrel IP Logo" className="logo-home" />
                    <div className="social-links">
                        <p className='left-text-home'>Follow Us On</p>
                        <div className='social-images-home'>
                            <a href="https://www.linkedin.com/company/squirrelip/" target="_blank" className="social-icon">
                                <img src={ld} alt="Linkdin Image" />
                            </a>
                            <a href="#" target="_blank" className="social-icon">
                                <img src={fb} alt="facebook Image" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="footer-contact">
                    <h3>CONTACT US</h3>
                    <p>Phone: +91 7977563694</p>
                    <p>Email: info.squirrelip@gmail.com</p>
                    {/* <p>Mumbai, Maharashtra</p> */}
                    <p>Working Days: Monday to Saturday</p>
                </div>

                <div className="footer-services">
                    <h3>SERVICES</h3>
                    <p>Patent Filing</p>
                    <p>Patent Commercialization</p>
                    <p>Patent Customization</p>
                    <p>Patent Workforce Community</p>
                </div>

                <div className="footer-pages">
                    <h3>PAGES</h3>
                    <p><a onClick={() => navigateToSection('about')}>About</a></p>
                    {/* <p><a onClick={() => navigateToSection('service')}>Services</a></p> */}
                    <p><a onClick={() => navigateToSection('marketplace')}>New Innovation</a></p>
                    <p><a onClick={() => navigateToSection('contact')}>Contact Us</a></p>
                </div>
            </footer>

            <div className="footer-rights">
                <p>© All rights reserved to Squirrel-IP</p>
            </div>
        </div>
    );
};

export default Homepage;

