import React, { useState } from "react";
import './AboutUs.css';
import ContactUsCommon from "../ContactUsCommon/ContactUsCommon";
import dummyImage from "../../images/who-are-we.jpg"; // Dummy image path
import NavBar from "../NavBar/NavBar";
import samrudhi from '../../images/samrudhi.png';
import akshat from '../../images/akshat.png';
import prasad from '../../images/prasad.png';
import SerCenterImage from '../../images/SerCenterImage.png';
import founderlinkedin from '../../images/founder-linkedin.png';


const AboutUs = () => {
    return (
        <section>
            <NavBar />
            <div className="who-container">
                <h3>Empowering Innovation, Fueling progress</h3>
                <h3>SQUIRREL IP, Where ideas takes flight!</h3>
            </div>
            <div >
                <div className="image-container1">
                    <img src={dummyImage} alt="Who Are We" />
                    <div className="text-overlay">
                        <h3></h3>
                        <div className="tagline">
                            <h2 >WHO &nbsp; ARE &nbsp; &nbsp; WE</h2>
                        </div>
                    </div>
                </div>
                <div className="description-container">
                    <p>
                        At SQUIRREL IP, we are building an online platform to connect innovators and industries, making it easier to bring new ideas to life. Our goal is to bridge the gap between innovation and commercialization by providing everything needed in one place.
                        We offer a range of services to help innovators commercialize their patents, create custom solutions for industries, and connect with the right partners. Our platform includes a marketplace for patent listings, an AI-powered tool for patent analysis, and a community space where innovators can share ideas, collaborate, and grow together.
                        Whether you are a scientist, startup, or industry expert, SQUIRREL IP is here to support your journey from innovation to impact. Together, we can build a thriving ecosystem for new technologies and unlock the full potential of patents.
                    </p>
                </div>
            </div>
            <div className="team-section">
                <h2 className="team-heading">Our Team</h2>
                <div className="team-members">
                    <div className="team-member">
                        <div className="founders-image">
                            <img src={samrudhi} className='founder-image' alt="Somuddhi Kharvilkar" />
                            <a href='https://www.linkedin.com/in/samruddhi-khanvilkar' target="_blank">
                                <div className="founder-linkedin">
                                    <img src={founderlinkedin} className='founders-actual-image' alt="linkedin Image" />
                                </div>
                            </a>
                        </div>

                        <div className="member-info">
                            <h3>Somuddhi Kharvilkar</h3>
                            <p>Co-founder & CEO</p>
                        </div>
                    </div>

                    <div className="team-member">
                        <div className="founders-image">
                            <img src={akshat} className='founder-image' alt="Akshat Mohite" />
                            <a href='https://www.linkedin.com/in/cosmoakshat' target="_blank">
                                <div className="founder-linkedin">
                                    <img src={founderlinkedin} className='founders-actual-image' alt="linkedin Image" />
                                </div>
                            </a>
                        </div>
                        <div className="member-info">
                            <h3>Akshat Mohite</h3>
                            <p>Co-founder & COO</p>
                        </div>
                    </div>

                    <div className="team-member">
                        <div className="founders-image">
                            <img src={prasad} className='founder-image' alt="Prasad karhad" />
                            <a href='https://www.linkedin.com/in/prasadkarhad' target="_blank">
                                <div className="founder-linkedin">
                                    <img src={founderlinkedin} className='founders-actual-image' alt="linkedin Image" />
                                </div>
                            </a>
                        </div>
                        <div className="member-info">
                            <h3>Prasad Karhad</h3>
                            <p>Sr. Legal Counsel</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Services Grid */}
            <h1 className="wwo">What We Offer</h1>
            <div className="service-grid-container">
                <div className="service-item top-left">
                    <div className='Ovalshape'>
                        <h3>Patent Commercialization</h3>
                    </div>
                    <p>
                        We assist innovators in turning their patents into successful business opportunities
                        by helping them license or sell their intellectual property to industries. Our platform ensures that patents are not just filed but also reach the right industries, unlocking their full market potential.
                    </p>

                </div>

                <div className="service-item top-right">
                    <div className='Ovalshape'>
                        <h3>Custom Patents</h3>
                    </div>
                    <p>
                        We work with businesses to create tailored patents that solve specific industry problems.
                        Through collaboration between innovators and companies, we develop unique,
                        market-ready solutions that can be patented, helping industries innovate faster and more effectively.                    </p>
                </div>

                <div className="center-icon">
                    <img src={SerCenterImage} alt="Center Icon" className="center-image" />
                </div>

                <div className="service-item bottom-left">

                    <p>
                        Our platform offers a dedicated space where innovators, startups, researchers, and institutions
                        can come together to share their knowledge, discuss ongoing projects, and collaborate on new ideas.
                        This vibrant community fosters partnerships, supports growth, and promotes innovation.
                    </p>
                    <div className='Ovalshape-bottom'>
                        <h3>Innovator’s Community</h3>
                    </div>
                </div>

                <div className="service-item bottom-right">

                    <p>
                        Innovators can showcase their granted patents on our platform, gaining exposure to
                        companies actively seeking new technologies. This helps bridge the gap between inventors
                        and industries, making it easier for both parties to connect and explore partnership opportunities.
                    </p>
                    <div className='Ovalshape-bottom'>
                        <h3>Patent Listing Platform</h3>
                    </div>
                </div>
            </div>
            <ContactUsCommon />
        </section>
    );
};

export default AboutUs;