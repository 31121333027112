import React, { useEffect, useState } from "react";
import "./Marketplace.css";
import cross from "../../images/cross.png";
import searchIcon from "../../images/searchIcon.png";
import NavBar from "../NavBar/NavBar";
import deleteIcon from "../../images/delete.png";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { patentsRoute } from "../../utils/apiRoutes";
import { useNavigate } from "react-router-dom";
import filter from "../../images/filter.png";
import shop from "../../images/shop.png";

const Marketplace = () => {
  // useAuth();
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [selectedValue, setselectedValue] = useState("");
  const [patents, setpatents] = useState([]);
  const [filteredPatents, setFilteredPatents] = useState([]);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    async function start() {
      const { data } = await axios.get(`${patentsRoute}/get-all-patents`);
      console.log(data.data);
      setpatents(data.data);
      setFilteredPatents(data.data); // Initialize with all patents
    }
    start();
  }, []);

  useEffect(() => {
    filterPatents();
  }, [appliedFilters, patents]); // Re-filter whenever filters or patents change

  // Function to filter patents based on applied filters
  const filterPatents = () => {
    let newFilteredPatents = patents;

    if (appliedFilters.length > 0) {
      newFilteredPatents = newFilteredPatents.filter((patent) => {
        return appliedFilters.every((filter) => {
          const lowerCaseFilter = filter.toLowerCase();
          if (lowerCaseFilter === "available" || lowerCaseFilter === "sold") {
            return patent.transactionType.toLowerCase() === lowerCaseFilter;
          }
          return (
            patent.sector.toLowerCase() === lowerCaseFilter ||
            patent.usedTech.toLowerCase() === lowerCaseFilter ||
            patent.patentType.toLowerCase() === lowerCaseFilter
          );
        });
      });
    }

    // Filter by search text in abstract and title fields
    if (searchText.trim()) {
      newFilteredPatents = newFilteredPatents.filter(
        (patent) =>
          patent.abstract.toLowerCase().includes(searchText.toLowerCase()) ||
          patent.title.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilteredPatents(newFilteredPatents);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of items per page
  const totalPages = filteredPatents
    ? Math.ceil(filteredPatents.length / itemsPerPage)
    : 1;

  const paginateItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredPatents ? filteredPatents.slice(startIndex, endIndex) : [];
  };

  // Handle page change
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to add filter to applied filters
  const addFilter = (filter) => {
    if (!appliedFilters.includes(filter)) {
      setAppliedFilters([...appliedFilters, filter]);
      setselectedValue("");
    }
  };

  // Function to remove a filter
  const removeFilter = (filter) => {
    setAppliedFilters(appliedFilters.filter((f) => f !== filter));
  };

  return (
    <div className="top-mp">
      <NavBar />

      <div className="search-container-mp">
        <div className="filters-mp">
          <span className="filter-item-mp">
            All <span className="count-mp">({filteredPatents.length})</span>
          </span>
          <span className="filter-item-mp">
            Available{" "}
            <span className="count-mp">
              (
              {
                filteredPatents.filter(
                  (ele) => ele.transactionType === "available"
                ).length
              }
              )
            </span>
          </span>
          <span className="filter-item-mp">
            Sold{" "}
            <span className="count-mp">
              (
              {
                filteredPatents.filter((ele) => ele.transactionType === "sold")
                  .length
              }
              )
            </span>
          </span>
        </div>

        <div className="search-bar-mp">
          <span className="search-icon-mp">
            <img src={searchIcon} />
          </span>
          <input
            type="text"
            placeholder="Search by keywords"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="marketplace-mp">
          <div className="marketplace-outer-name-mp">
            <div className="marketplace-design-name-mp">
              <h2>
                <img className="filter-img-mp" src={shop} />
                New Innovation
              </h2>
            </div>
            {/* Filters Section */}
            <div className="filters-section-mp">
              <h2>
                <img className="filter-img-mp" src={filter} />
                Filters
              </h2>
              <div className="filter-block-mp">
                <div className="filter-category-mp">
                  <label>Industry</label>
                  <select
                    onChange={(e) => addFilter(e.target.value)}
                    value={selectedValue}
                  >
                    <option value="" disabled selected>
                      Select Industry
                    </option>
                    {/* <option value="aerospace">Aerospace</option>
                    <option value="defence">Defence</option>
                    <option value="technology">Technology</option> */}
                    <option>Chemistry and Pharmaceuticals</option>
                    <option>Biotechnology and Life Sciences</option>
                    <option>Electronics and Communication Technology</option>
                    <option>Mechanical Engineering and Manufacturing</option>
                    <option>Automotive and Transportation</option>
                    <option>Information Technology and Software</option>
                    <option>Medical Devices and Healthcare</option>
                    <option>Energy and Environmental Technology</option>
                    <option>Agriculture and Food Processing</option>
                    <option>Consumer Goods and Household Appliances</option>
                    <option>Aerospace and Defense</option>
                    <option>Nanotechnology</option>
                    <option>Construction and Civil Engineering</option>
                  </select>
                </div>

                <div className="filter-category-mp">
                  <label>Technology</label>
                  <select
                    onChange={(e) => addFilter(e.target.value)}
                    value={selectedValue}
                  >
                    <option value="" disabled selected>
                      Select Technology
                    </option>
                    <option value="aiml">AI-ML</option>
                    <option value="radar">Radar</option>
                    <option value="electronicwarfare">
                      Electronic Warfare
                    </option>
                  </select>
                </div>

                <div className="filter-category-mp">
                  <label>Transaction Type</label>
                  <select
                    onChange={(e) => addFilter(e.target.value)}
                    value={selectedValue}
                  >
                    <option value="" disabled selected>
                      Select Transaction Type
                    </option>
                    <option value="sold">Sell</option>
                    <option value="available">License</option>
                    <option value="both">Both</option>
                  </select>
                </div>

                {/* <div className="filter-category-mp">
                  <label>Type of Patent</label>
                  <div className="patent-options-mp">
                    <button onClick={() => addFilter("design")}>Design</button>
                    <button onClick={() => addFilter("utility")}>Utility</button>
                    <button onClick={() => addFilter("plant")}>Plant</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="filter-item-section-mp">
            {/* Applied Filters Section */}
            <div className="applied-filters-section-mp">
              <h3>Applied Filters</h3>
              <div className="applied-filters-mp">
                {appliedFilters.length > 0 ? (
                  appliedFilters.map((filter, index) => (
                    <div key={index} className="applied-filter-mp">
                      <p>{filter}</p>
                      <button
                        onClick={() => removeFilter(filter)}
                        className="filterInButton-mp"
                      >
                        <img src={cross} />
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No filters applied</p>
                )}
              </div>
            </div>

            {/* Items List Section */}
            <div className="items-list-section-mp">
              <h3>Patents</h3>
              <div className="items-list-mp">
                {paginateItems().length > 0 ? (
                  paginateItems().map((patent) => (
                    <div
                      className="item-mp"
                      key={patent.patentId} // Use unique patent ID
                      onClick={() =>
                        navigate(`/description/${patent.patentId}`)
                      }
                    >
                      <div className="outer-ima-mp">
                        <img
                          src={
                            patent.patentImages[0]
                              ? patent.patentImages[0]
                              : "/default-image.png"
                          }
                          alt={patent.title}
                        />
                        <div className="Pate-mp">
                          <p>{patent.title}</p>
                          <p>Sector: {patent.sector}</p>
                        </div>
                      </div>
                      <div className="item-list-bin-mp">
                        <span
                          className={`availability-mp ${
                            patent.transactionType === "sold"
                              ? "availability-sold-mp"
                              : ""
                          }`}
                        >
                          {patent.transactionType}
                        </span>
                        {/* <button>
                          <img src={deleteIcon} className="item-list-bin-mp" />
                        </button> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Patent Found</p>
                )}
              </div>

              {/* Pagination Controls */}
              <div className="pagination-mp">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    className={currentPage === index + 1 ? "active" : ""}
                    onClick={() => changePage(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
