import React, { useState, useRef, useEffect } from "react";
import "./NavBar.css";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import user from "../../images/user.png";
import axios from "axios";
import { auth, profileRoute } from "../../utils/apiRoutes";
import useAuth from "../../hooks/useAuth";
import useUserData from "../../hooks/useUserData";
import DeleteAccountCard from "../DeleteAccount/DeleteAcc";
import sq from "../../images/sqlg.png";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    avatar: "",
  });
  const [errors, setErrors] = useState();
  useUserData(setUserData, setErrors);

  const [isLoggedIn, setIsLoggedIn] = useState(true); // Set to true if user is logged in
  // const {pathname} = useLocation()
  const isAuth = useAuth(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle between true/false
  };

  // const toggleDropdown = () => {
  //   setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
  // };

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener to close dropdown on outside click
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      // Make a request to your backend to clear the cookie
      const response = await axios.post(
        `${auth}/logout`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        // Handle logout logic
        setIsLoggedIn(false);
        localStorage.removeItem("squirrelUser");
        navigate("/home"); // Navigate to home or login page
      }
    } catch (error) {
      console.error("Logout failed:", error);
      // Optionally handle errors, such as showing a message to the user
    }
  };

  const scrollToSection = (task) => {
    const servicesSection = document.getElementById(task);
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateToSection = (task) => {
    navigate("/" + task);
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleDeleteClick = () => {
    setIsPopupVisible(true);
  };

  const handleConfirm = async () => {
    console.log("Account deleted");
    await deleteUser();
    setIsPopupVisible(false);
  };

  const handleCancel = () => {
    // Close the popup without navigating away
    setIsPopupVisible(false);
  };

  const deleteUser = async () => {
    try {
      const response = await axios.delete(`${profileRoute}/delete-user`, {
        withCredentials: true, // to send cookies with the request
      });

      if (response.status === 200) {
        console.log("User deleted successfully", response.data);
        handleLogout();
        return response.data; // return the data on success
      } else {
        console.error("Failed to delete user", response.data);
      }
    } catch (error) {
      console.error(
        "Error deleting user:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <>
      {/* Navbar */}
      <nav className="navbar">
        <div className="logo" onClick={() => navigateToSection("")}>
          <img src={sq} />
        </div>
        <ul className={isOpen ? "nav-links open" : "nav-links"}>
          <li onClick={() => navigateToSection("")}>
            <a>Home</a>
          </li>
          <li onClick={() => navigateToSection("about")}>
            <a>About</a>
          </li>
          {/* <li onClick={() => navigateToSection("service")}>
            <a>Services</a>
          </li> */}
          <li onClick={() => navigateToSection("contact")}>
            <a>Contact</a>
          </li>
          <li onClick={() => navigateToSection("marketplace")}>
            <a>New Innovation</a>
          </li>
          <li>
            {isAuth ? (
              <div className="profile-dropdown">
                <img
                  src={userData.avatar && userData.avatar}
                  // alt="User Profile"
                  className="user-profile-image"
                  onClick={toggleDropdown}
                />
                {dropdownVisible && (
                  <div className="dropdown-menu" ref={dropdownRef}>
                    {/* <a href="#dashboard">Dashboard</a> */}
                    <a onClick={() => navigateToSection("profile")}>Profile</a>
                    {/* <a href="#patent">Patent</a> */}
                    <a onClick={handleDeleteClick}>Delete Account</a>
                    {isPopupVisible && (
                      <DeleteAccountCard
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                      />
                    )}
                    {/* <a href="#deleteAccount" onClick={deleteUser}>
                      Delete Account{" "}
                    </a> */}
                    <a href="#logout" onClick={handleLogout}>
                      Logout
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <button
                className="join-us-btn"
                onClick={() => navigateToSection("home")}
              >
                Join Us
              </button>
            )}
          </li>
          {/* <li onClick={() => scrollToSection('cust-pt')}><a>Custom-patent</a></li> */}
        </ul>
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
