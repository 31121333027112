export const host = "https://api.squirrelip.com";
export const auth = `${host}/api/auth`;
export const patentsRoute = `${host}/api/patent`;
export const intRoute = `${host}/api/interaction`;
export const profileRoute = `${host}/api/profile`;
export const subsRoute = `${host}/api/subscribe`;

//http://13.61.61.9:5000
//export const host = "http://13.61.61.9:5000";

//http://localhost:5000
//103.185.175.138

//https://squirrel-server.vercel.app

//dipesh
//https://squirrel-server-t2qw.vercel.app

//change

//https://api.squirrelip.in
