// import React from 'react';
import React, { useState } from "react";
import useUserData from "../../hooks/useUserData";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import "./DeleteAcc.css";
import axios from "axios";
import { auth, profileRoute } from "../../utils/apiRoutes";
import useAuth from "../../hooks/useAuth";

const DeleteAccountCard = ({ onConfirm, onCancel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    avatar: "",
  });
  const [errors, setErrors] = useState();
  useUserData(setUserData, setErrors);

  const [isLoggedIn, setIsLoggedIn] = useState(true); // Set to true if user is logged in
  // const {pathname} = useLocation()
  const isAuth = useAuth(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle between true/false
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
  };

  const handleLogout = async () => {
    try {
      // Make a request to your backend to clear the cookie
      const response = await axios.post(
        `${auth}/logout`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        // Handle logout logic
        setIsLoggedIn(false);
        localStorage.removeItem("squirrelUser");
        navigate("/home"); // Navigate to home or login page
      }
    } catch (error) {
      console.error("Logout failed:", error);
      // Optionally handle errors, such as showing a message to the user
    }
  };

  const scrollToSection = (task) => {
    const servicesSection = document.getElementById(task);
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateToSection = (task) => {
    navigate("/" + task);
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleDeleteClick = () => {
    setIsPopupVisible(true);
  };

  const handleConfirm = () => {
    console.log("Account deleted");
    setIsPopupVisible(false);
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
  };

  const deleteUser = async () => {
    try {
      const response = await axios.delete(`${profileRoute}/delete-user`, {
        withCredentials: true, // to send cookies with the request
      });

      if (response.status === 200) {
        console.log("User deleted successfully", response.data);
        handleLogout();
        return response.data; // return the data on success
      } else {
        console.error("Failed to delete user", response.data);
      }
    } catch (error) {
      console.error(
        "Error deleting user:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <div className="overlay-dacc">
      <div className="card-dacc">
        <h2>Are you sure you want to delete your account?</h2>
        <div className="buttons-dacc">
          <button className="confirm-dacc" onClick={onConfirm}>
            Confirm
          </button>
          <button className="cancel-dacc" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountCard;
