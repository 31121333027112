import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./ProfileImage.css";
import { FiUpload, FiTrash } from "react-icons/fi";

Modal.setAppElement("#root");

const ProfileImage = ({ handleAvatarLogo, image }) => {
  console.log(image);
  const [avatar, setAvatar] = useState(null); // Store file
  const [preview, setPreview] = useState(); // Separate preview state for image display
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatar(file); // Store file in state
      setPreview(URL.createObjectURL(file)); // Generate URL for preview
      setModalIsOpen(true);
      handleAvatarLogo(file); // Send the file to the parent component
    }
  };

  const handleAvatarDelete = () => {
    handleAvatarLogo(null); // Notify parent of deletion
    setAvatar(null);
    setPreview(null);
  };

  const handleSaveCroppedImage = () => {
    setModalIsOpen(false);
    // Save action if additional logic is needed
  };

  useEffect(() => {
    setPreview(image === "" ? null : image);
    console.log("preview", preview);
  }, [image]);

  return (
    <div className="profileAvatarContainer-editprofile">
      <div className="avatarPreview-editprofile">
        {preview ? (
          <img src={preview} alt="Avatar" className="avatarImage-editprofile" />
        ) : (
          <div className="defaultAvatar-editprofile">No Avatar</div>
        )}
      </div>

      <button
        className="uploadNewAvatar-editprofile"
        onClick={() => document.getElementById("fileInput-editprofile").click()}
      >
        <FiUpload /> Upload New
      </button>
      <input
        id="fileInput-editprofile"
        type="file"
        accept="image/*"
        onChange={handleAvatarUpload}
        style={{ display: "none" }}
      />

      {avatar && (
        <button
          className="deleteAvatar-editprofile"
          onClick={handleAvatarDelete}
        >
          <FiTrash /> Delete Avatar
        </button>
      )}

      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Preview Image"
        className="Modal-editprofile"
        overlayClassName="Overlay-editprofile"
      >
        <h2>Preview Your Image</h2>
        <div className="imagePreviewContainer-editprofile">
          <img
            src={preview}
            alt="Preview Avatar"
            className="previewImage-editprofile"
          />
        </div>

        <div className="cropControls-editprofile">
          <button onClick={handleSaveCroppedImage}>Save</button>
          <button onClick={() => setModalIsOpen(false)}>Cancel</button>
        </div>
      </Modal> */}
    </div>
  );
};

export default ProfileImage;
